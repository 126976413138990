<template>
  <div id="app">
    <vue-progress-bar />
    <Navigation />
    <main>
        <router-view />
    </main>
    <Footer />
    <div class="cookie-consent">
      <p class="cookie-consent__text"><v-icon class="cookie-consent__icon" name="cookie-bite" scale="1.5" />This website uses cookies. By using this website you agree to our <router-link to="/cookies" class="cookie-consent__link">Cookie Policy</router-link> and <router-link to="/privacy" class="cookie-consent__link">Privacy Policy</router-link></p>
      <button class="cookie-consent__button" @click="acceptCookie"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Accept</button>
      <button @click.prevent="hideCookie" class="cookie-consent__button-close">
        <v-icon class="cookie-consent__icon-close" name="times" scale="1.5" />
      </button>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import Icon from 'vue-awesome/components/Icon'
import SimpleLineIcons from 'vue-simple-line'
import 'vue-awesome/icons/cookie-bite'
import 'vue-awesome/icons/times'
export default {
  components: {
    Navigation,
    Footer,
    SimpleLineIcons,
    'v-icon': Icon
  },
  data: function () {
    return {
      cookie: true
    }
  },
  methods: {
    acceptCookie () {
      this.$cookie.set('branding_pavilion_cookie', 'true', 360)
      this.cookie = true
    },
    hideCookie () {
      document.querySelector('.cookie-consent').style.display = 'none'
    }
  },
  created () {
    // console.log(process.env.VUE_APP_ENV);
    if (this.$cookie.get('branding_pavilion_cookie') == null) {
      this.cookie = false
    }
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish()
    })

    console.log(`%c
.#####...#####....####...##..##..#####...######..##..##...####...........#####....####...##..##..######..##......######...####...##..##.
.##..##..##..##..##..##..###.##..##..##....##....###.##..##..............##..##..##..##..##..##....##....##........##....##..##..###.##.
.#####...#####...######..##.###..##..##....##....##.###..##.###..........#####...######..##..##....##....##........##....##..##..##.###.
.##..##..##..##..##..##..##..##..##..##....##....##..##..##..##..........##......##..##...####.....##....##........##....##..##..##..##.
.#####...##..##..##..##..##..##..#####...######..##..##...####...........##......##..##....##....######..######..######...####...##..##.
`, 'font-family:monospace; font-size: 15px;')
  },
  mounted () {
    this.$Progress.finish()
  }
}
</script>
